.dashboard{
  &__map:not(.map__fullscreen) {
    margin-bottom: -70px;

    .map__buttons {
      bottom: 65px
    }

    .leaflet-control-attribution {
      margin-bottom: 44px;
      margin-right: 38px;
    }
  }

  &__search{
    z-index: 1000;
  }

  &__content {
    margin: 32px;

    .mobile & {
      margin: 16px;
    }
  }
}

.mobile {
  .dashboard__map {
    &:not(.map__fullscreen) {
      .map__container {
        height: 25rem !important;
      }
    }
  }
}

