.q-drawer {
    &--left {
        &.q-drawer--bordered {
            border-right: 1px solid $neutral-10;
        }
    }

    &--right {
        margin-top: 8px;
    }

    &--mini-animate .q-drawer__content {
        white-space: initial !important;
    }
}

.sidebar {
    &__header {
        background-color: $neutral-5;
    }
}

.sidebar-left {
    .search {
        max-width: 250px;
    }

    .tree--full-height {
        position: absolute;
        top: 74px;
        right: 0;
        bottom: 0;
        left: 0;
        overflow-x: hidden;

        &.tree--filterable {
            top: 114px;
        }
    }
}

.mobile .sidebar-left .tree--full-height {
    position: static;
}

.sidebar-right {
    border-radius: 8px 0 0 0;

    &__navigation {
        display: flex;
        flex-direction: column;
        padding-top: 24px;
        width: 64px;
        background-color: $neutral-90;
        color: $neutral-40;

        align-items: center;

        > button:not(:first-child) {
            margin-top: 24px;
        }
    }

    .heading-4 {
        margin: 2.4rem 0;
    }


}

