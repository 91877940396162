.comment-list {
  width: 100%;

  &__group {
    .q-item {
      border-bottom: 1px solid $neutral-20;
    }
  }
}

.comment {
  @extend %text-small;

  width: 100%;
  padding: 12px 16px;
  background-color: $white;
  border-bottom: 1px solid $neutral-20;
  position: relative;

  &--reply {
    padding: 12px 16px 12px 32px;

    &:before {
      content: "";
      display: inline-block;
      width: 12px;
      height: 34px;
      border-bottom: 1px solid $neutral-40;
      border-left: 1px solid $neutral-40;
      position: absolute;
      left: 12px;
      top: 0;
    }
  }

  &__header,
  &__sub-header {
    display: flex;
    align-items: center;
  }

  &__info {
    div:first-child {
      max-width: 200px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  &__sub-header {
    margin-top: 8px;
  }

  &__content {
    padding: 16px 0;
  }

  &__actions {
    //
  }
}