* {
  margin: 0;
  padding: 0;
}

*,
::after,
::before {
  box-sizing: inherit;
}

.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
