$firasans-font-family: "FiraSans" !default;
$firasans-font-path: "/assets/fonts/firasans" !default;

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url('/web-font/flUhRq6tzZclQEJ-Vdg-IuiaDsNcIhQ8tQ.woff2') format('woff2'), url('/web-font/flUhRq6tzZclQEJ-Vdg-IuiaDsNa.woff') format('woff');
}

@font-face {
  font-family: '#{$firasans-font-family}';
  src:  url('#{$firasans-font-path}/#{$firasans-font-family}-Regular.woff2') format("woff2"),
        url('#{$firasans-font-path}/#{$firasans-font-family}-Regular.woff') format("woff"),
        url('#{$firasans-font-path}/#{$firasans-font-family}-Regular.eot') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: '#{$firasans-font-family}';
  src:  url('#{$firasans-font-path}/#{$firasans-font-family}-Italic.woff2') format("woff2"),
        url('#{$firasans-font-path}/#{$firasans-font-family}-Italic.woff') format("woff"),
        url('#{$firasans-font-path}/#{$firasans-font-family}-Italic.eot') format('truetype');
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: '#{$firasans-font-family}';
  src:  url('#{$firasans-font-path}/#{$firasans-font-family}-Medium.woff2') format("woff2"),
        url('#{$firasans-font-path}/#{$firasans-font-family}-Medium.woff') format("woff"),
        url('#{$firasans-font-path}/#{$firasans-font-family}-Medium.eot') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: '#{$firasans-font-family}';
  src:  url('#{$firasans-font-path}/#{$firasans-font-family}-MediumItalic.woff2') format("woff2"),
        url('#{$firasans-font-path}/#{$firasans-font-family}-MediumItalic.woff') format("woff"),
        url('#{$firasans-font-path}/#{$firasans-font-family}-MediumItalic.eot') format('truetype');
  font-style: italic;
  font-weight: 500;
}

@font-face {
  font-family: '#{$firasans-font-family}';
  src:  url('#{$firasans-font-path}/#{$firasans-font-family}-SemiBold.woff2') format("woff2"),
        url('#{$firasans-font-path}/#{$firasans-font-family}-SemiBold.woff') format("woff"),
        url('#{$firasans-font-path}/#{$firasans-font-family}-SemiBold.eot') format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: '#{$firasans-font-family}';
  src:  url('#{$firasans-font-path}/#{$firasans-font-family}-SemiBoldItalic.woff2') format("woff2"),
        url('#{$firasans-font-path}/#{$firasans-font-family}-SemiBoldItalic.woff') format("woff"),
        url('#{$firasans-font-path}/#{$firasans-font-family}-SemiBoldItalic.eot') format('truetype');
  font-style: italic;
  font-weight: 600;
}

@font-face {
  font-family: '#{$firasans-font-family}';
  src:  url('#{$firasans-font-path}/#{$firasans-font-family}-Bold.woff2') format("woff2"),
        url('#{$firasans-font-path}/#{$firasans-font-family}-Bold.woff') format("woff"),
        url('#{$firasans-font-path}/#{$firasans-font-family}-Bold.eot') format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: '#{$firasans-font-family}';
  src:  url('#{$firasans-font-path}/#{$firasans-font-family}-BoldItalic.woff2') format("woff2"),
        url('#{$firasans-font-path}/#{$firasans-font-family}-BoldItalic.woff') format("woff"),
        url('#{$firasans-font-path}/#{$firasans-font-family}-BoldItalic.eot') format('truetype');
  font-style: italic;
  font-weight: 700;
}
