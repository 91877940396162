.q-list--separator > .q-item-type + .q-item-type {
  border-top: 1px solid $neutral-10;
}

.expansion {
  margin: 24px -16px 0;

  .dialog &{
    margin: 24px 0 0;
  }

  &:first-child {
    margin-top: -16px;
  }

  .mobile & {
    &:first-child {
      margin-top: 0;
    }
  }

  &__item {
    font-weight: 500;
    padding: 16px;

    > *:not(:last-child) {
      margin-right: 16px
    }
  }

  &__content {
    > * {
      padding: 16px;
    }
  }

  .mobile & {
    margin: 16px -16px 0;
  }
}

.tree {
  &.q-list {
    color: $neutral-80;
    background-color: $white;
    overflow-y: auto;
  }

  .active {
    font-weight: bold;
  }

  .q-expansion-item {
    &__toggle-icon {
      color: $neutral-40;
    }
  }

  .q-item {
    &__label {
      word-break: break-word;
    }

    &__section--avatar {
        min-width: 36px;
    }
  }


  .tree__item {
    &--last {
      background-color: $neutral-5;
      padding: 4px 16px;
    }

    &--active {
      font-weight: 600;
    }
  }

  .pagination-item {
    display: flex;
    align-items: center;

    padding-left: 0;
    border-bottom: 0;
  }
}
