.page-card {
  .mobile & {
    box-shadow: none;
  }

  &__map {
      height: 20rem;
  }

  &__main-content {
    padding: 48px;

    .mobile & {
      padding: 24px 16px 16px;
    }
  }

  &__details {
    background-color: $neutral-5;
    padding: 48px;

    .mobile & {
      padding: 16px;
    }
  }

  &__actions {
    padding: 24px 48px 24px;

    .mobile & {
      padding: 16px;
    }
  }
}
