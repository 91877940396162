@import './../quasar.scss';
@import '../../../../../../node_modules/quasar/src/css/index.sass';

@import './abstracts/variables';
@import './abstracts/functions';
@import './abstracts/mixins';
@import './abstracts/placeholders';

@import './base/fonts';
@import './base/base';
@import './base/colors';
@import './base/typography';
@import './base/helpers';
@import './base/flexbox_order';

@import './layout/header';
@import './layout/navigation';
@import './layout/sidebars';
@import './layout/topbar';
@import './layout/main_content';
@import './layout/page_card';
@import './layout/page_toolbar';
@import './layout/form';
@import './layout/pdf';

@import './pages/project';
@import './pages/dashboard';
@import './pages/homepage';

@import './components/badges_chips';
@import './components/banners';
@import './components/buttons';
@import './components/breadcrumbs';
@import './components/cards';
@import './components/comment';
@import './components/dialog';
@import './components/expansion_tree';
@import './components/links';
@import './components/lists';
@import './components/map';
@import './components/modals';
@import './components/pagination';
@import './components/tooltip';
@import './components/scroll_area';
@import './components/values';

@import "../../../../../../resources/assets/sass/overwrites.scss";

.q-notifications__list--bottom {
  bottom: 66px;
}


