.type-chip {
  @include border(1px, $neutral-20);

  color: $neutral-90;
  font-weight: 500;
  font-size: 1.1rem;
  padding: .2em .9em;
}

.truncate-chip-labels {
  max-width: 150px;
}

.count-chip {
  @include border(1px, $neutral-20);

  font-size: 1.1rem;
  font-weight: 500;
  border-radius: 100px;
  background-color: $white;
  max-width: fit-content;
}

.action-chip {
  @extend %dark-semi-bold;
  @include border(1px, $neutral-20);
  color: $dark;
  margin: 0;
  font-size: 1.1rem;
  cursor: pointer;

  .q-icon {
    @extend %dark-semi-bold;
    font-size: 1.5em;
  }

  &:hover {
    color: $white;
    background-color: $primary !important;
    @include border(1px, $primary);

    .q-icon {
      color: $white;
    }
  }
}

.common-chip {
  @include border(1px, $neutral-20);

  font-size: inherit;
  height: 38px;
  border-radius: 100px;
  padding: 1.6rem 2.4rem;
  color: $neutral-90;
  background-color: transparent;

  display: flex;
  align-items: center;

  .q-chip__icon--remove {
    margin-left: 0.2em;
  }

  &--filled {
    @include border(1px, $primary);
    color: $white;
    background-color: $primary;

    .q-chip__icon--remove {
      color: white;
      margin-left: 0.2em;
    }
  }
}

a.common-chip,
button.common-chip {
  cursor: pointer;
  background-color: white;

  &:hover, &:focus {
    @include border(1px, $primary);
    color: $white;
    background-color: $primary;
  }

  &--filled {
    &:hover, &:focus {
      color: $neutral-90;
      background-color: white;
    }
  }
}
