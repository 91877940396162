.q-banner{
  &__content {
    font-size: 1.6rem;
    line-height: 1.6;
  }
}

.banner {
  &__outlined {
    &--info {
      @include border(1px, $primary);

      background-color: $neutral-10;
    }

    &--warning {
      @include border(1px, $warning);

      background-color: scale-color($warning, $lightness: 90%);
    }
  }
}

.mobile {
  .q-banner{
    &__content {
      font-size: 1.4rem;
      line-height: 1.6;
      margin-bottom: 1rem;
    }
  }
}