.q-field--outlined {
  .q-field__control {
    &:before {
      border: 1px solid #939393;
    }

    &:hover:before {
      border: 1px solid $primary;
    }
  }
}

.q-field:not(.q-field--float) {
  input[type='file'] + .q-field__label {
    top: 0;
  }
}

.form {
  &__select {
    font-size: 1.6rem;
    min-width: 24.5rem;

    .mobile & {
      font-size: 1.4rem;
    }
  }
}

.title-input {
  font-size: 2.8rem;
  font-weight: bold;
  color: $dark;

  .q-placeholder {
    color: $neutral-60;
  }
}

.q-option-group > div {
  margin-top: 8px;
  margin-bottom: 8px;
}

.rule-code-generator {
  @include border(1px, $neutral-20);
  border-radius: $generic-border-radius;

  &__code {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $neutral-5;

    input {
      color: $neutral-60;
      font-weight: 600;
      border-radius: inherit;
      text-align: center;
    }
  }

  .q-field--standard {
    .q-field__control {
      padding: 0 12px;
    }
  }
}

.map-selector {
  @include border(1px, $neutral-20);
  border-radius: $generic-border-radius;
  padding-right: 8px;
  padding-bottom: 8px;
  height: 360px;

  .q-item {
    padding: 3px 0;

    &__section {
      .q-item__label {
        //overflow: hidden;
        //text-overflow: ellipsis;
        //white-space: nowrap;
      }

      &--side {
        padding: 0;
      }

      &--avatar {
        min-width: 10px;
      }
    }
  }

  .col-4 {
    overflow-y: auto;
    height: 286px;

    .q-tree {
      @include border(1px, $neutral-20);
      border-radius: $generic-border-radius;
      min-height: 278px;
    }
  }

  .map--fullscreen {
    .map__sidebar {
      .q-tree {
        min-height: 100%;
      }
    }
  }

  &.error {
    height: 383.3333px;

    .col-4 {
      .q-tree {
        @include border(1.8333px, $negative);
      }
    }
  }
}

.hide-label {
  .q-field__label {
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    height: 1px;
    width: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
  }

  &.q-field--auto-height.q-field--labeled {
    .q-field__control-container {
      padding-top: 8px;
    }
  }
}

.show-label {
  .q-field__label {
    padding-bottom: 16px
  }
}
