$ordering: (
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10',
    '11',
    '12',
);

@mixin order-modifiers($attribute: 'order') {
  @each $order in $ordering {
    &-#{$order} {
      #{$attribute}: $order;
    }
  }
}

.order {
  @include order-modifiers;
}

@media (max-width: 599px) {
  .order-xs {
    @include order-modifiers;
  }
}

@media (max-width: 1023px) {
  .order-sm {
    @include order-modifiers;
  }
}

@media (max-width: 1439px) {
  .order-md {
    @include order-modifiers;
  }
}

@media (max-width: 1919px) {
  .order-lg {
    @include order-modifiers;
  }
}

@media (min-width: 1920px) {
  .order-xl {
    @include order-modifiers;
  }
}
