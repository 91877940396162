.main {
  padding: 80px 24px 24px;
  background-color: $neutral-5;

  &.no-sticky-header {
    padding-top: 48px
  }

  .mobile &{
    padding: 0;
  }
}