$success: #091C34;
$error: $negative;

$colors: (
    black-100  : #222121,
    neutral-90 : #383737,
    neutral-80 : #4E4D4D,
    neutral-70 : #646464,
    neutral-60 : #6F6F6F,
    neutral-40 : #AEADAD,
    neutral-20 : #D3D3D3,
    neutral-10 : #E9E9E9,
    neutral-5  : #FAFAFA,
    success    : $success,
    error      : $error,
);

@mixin color-modifiers($attribute: 'color') {
  @each $name, $hex in $colors {
    &-#{$name} {
      #{$attribute}: $hex;
    }
  }
}

@mixin bg-color-modifiers($attribute: 'background-color') {
  @each $name, $hex in $colors {
    &-#{$name} {
      #{$attribute}: $hex;
    }
  }
}

.text {
  @include color-modifiers;
}

.bg {
  @include bg-color-modifiers;
}
