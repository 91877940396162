.q-list {
    &--bordered {
        @include border(1px, $neutral-10);
        background-color: $white;
    }
}

ul {
    list-style: none;
}

.editor-styling {
    ul {
        list-style: circle !important;
    }

    ul, ol {
        padding-left: 20px;
    }
}
