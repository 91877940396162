.homepage {
  .card-container {
    padding: 5%;
  }

  &__card {
    max-width: 600px;
  }
}

.backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background: url($homepage_image_url) no-repeat 50% fixed;
  background-size: cover;
  overflow: hidden;
}
