.link {
  &:focus, &:focus-visible {
    outline: 1px solid $primary;
  }

  &--no-underline {
    text-decoration: none;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  &--dark:link,
  &--dark:visited {
    color: $text-base;

    &:focus,
    &:hover {
      color: $primary;
    }
  }

  &--grey:link,
  &--grey:visited {
    color: $neutral-70;

    &:focus,
    &:hover {
      color: $primary;
    }
  }

  &--primary:link,
  &--primary:visited {
    color: $primary;

    &:focus,
    &:hover {
      color: $primary;
    }
  }

  &--light:link,
  &--light:visited {
    color: $white;

    &:focus,
    &:hover {
      color: $white;
    }
  }
}

.icon-link {
  display: flex;
  align-items: center;

  .q-icon {
    font-size: larger;
  }
}