.q-header {
  box-shadow: 0 4px 30px rgba(34, 33, 33, 0.04);
}

.header {
  padding: 0 24px;


  &--button {
    color: white;
    text-decoration: none;
  }


  &--logo {
    border: 1px solid white;
    border-radius: 4px !important;
    background-color: $primary;

    &--white {
      border: none;
      border-radius: 0 !important;
    }
  }

  &--pattern {
    position: absolute;
    z-index: -1;
    height: 50px;
    left: 0;
    right: 0;
    top: 0;
  }

  &--instance-logo {
    margin: 0 .5em;
    position: relative;
    vertical-align: middle;
    display: inline-block;

    &--white {
      background-color: white;
      border: 1px solid white;
      box-sizing: content-box;
    }
  }
}
