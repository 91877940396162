// ACCESSIBILITY
.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

// BOX SHADOWS
.shadow{
  &--low {
    @extend %shadow--low
  }

  &--medium,
  &--bottom {
    @extend %shadow--medium
  }

  &--high {
    @extend %shadow--high
  }

  &--top{
    @extend %shadow--top
  }

  &--right {
    @extend %shadow--right
  }

  &--left {
    @extend %shadow--left
  }
}

// TEXT
.text-caption {
  font-size: 1.2rem;
}

.break-text {
  word-break: break-word;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

// POSITIONING

.display-inline {
  display: inline-block;
}