@import "quasar.variables";

.q-notifications__list--bottom {
  bottom: 66px;
}


element {
}

h3, .heading-3 {
  font-size: 2rem;

}

h1, h2, h3, h5, h6, .heading-1, .heading-2, .heading-3 {
  font-family: "FiraSans", "FiraSans", Arial, sans-serif;

  line-height: 1.2;

  font-weight: 500;

}

.q-mr-sm {
  margin-right: 8px;

}

*, ::after, ::before {
  box-sizing: inherit;

}

* {
  margin: 0;

  margin-right: 0px;

  padding: 0;

}

*, ::before, ::after {
  box-sizing: inherit;

  -webkit-tap-highlight-color: transparent;

  -moz-tap-highlight-color: transparent;

}

.q-dialog__inner > div {
  pointer-events: all;

}

@media (prefers-color-scheme: dark) {
  * {
    --dialog-bg-color: white !important;
    --dialog-border-color: white !important;
    --dialog-shadow: 0 2px 14px 0 rgb(58 57 68 / 0.2) !important;
    --text-primary-color: #15141a !important;
    --text-secondary-color: #5b5b66 !important;
    --hover-filter: brightness(0.9) !important;
    --focus-ring-color: #0060df !important;
    --textarea-border-color: #8f8f9d !important;
    --textarea-bg-color: white !important;
    --radio-bg-color: #f0f0f4 !important;
    --radio-checked-bg-color: #fbfbfe !important;
    --radio-border-color: #8f8f9d !important;
    --radio-checked-border-color: #0060df !important;
    --button-secondary-bg-color: #f0f0f4 !important;
    --button-primary-bg-color: #0060df !important;
    --button-primary-fg-color: #fbfbfe !important;
  }
}
