.q-card {
  @extend %shadow--low;

  &__actions {
    &--backable {
      position: relative;
      padding-left: 60px;

      button:last-child {
        position: absolute;
        left: 0;
      }
    }
  }
}
