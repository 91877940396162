.navigation {
  background-color: $white;
  height: 7.2rem;
  padding: 0 24px;
  font-size: 1.6rem;

  &--mobile {
    height: auto;
    background-color: $primary;
    font-size: 1.4rem;
    padding: 0 16px;
  }

  &__nav {
    display: flex;
    list-style: none;
  }

  &__item {
    display: inline;
    margin-right: 4px;
    height: 4.8rem;
  }

  &__menu {
    min-width: 19.5rem;

    .nav-list__item {
      padding: 0;

      a {
        color: $text-base;
        text-decoration: none;
        padding: 8px 16px;

        &:focus,
        &:hover {
          color: $primary;
          text-decoration: underline;
        }
      }
    }
  }

  &__menu-button,
  &__link:link,
  &__link:visited {
    @include border(2px, $white);

    color: $text-base;
    text-decoration: none;
    padding: 1rem 2rem;
    height: 4.8rem;
    border-radius: $generic-border-radius;
    display: inline-block;

    &:hover,
    .navigation__item--active & {
      @include border(2px, $neutral-5);

      color: $primary;
      background-color: $neutral-5;
      text-decoration: underline;
    }

    &:focus {
      @include border(2px, $primary);
    }
  }

  &__menu-button {
    @include border(2px, $white);

    padding-bottom: 11px;
    background-color: transparent;
  }

  &__avatar {
    color: $white;
    background-color: $primary;
  }
}

.mobile-menu {
  &__account {
    height: 200px;
    color: $white;
    background-color: $secondary;
    //TODO add svg background
    //background-image: url(../../assets/img/topography_pattern-header.svg);
    //background-size: cover;
    //background-position: top;
    //position: relative;
  }

  .q-list {
    padding: 16px;
    background-color: $primary;

    .q-item {
      padding: 0;
      color: white;
      border-radius: $generic-border-radius;

      &:hover {
        color: $primary;
        background-color: $white;

        .q-item__section--main {
          text-decoration: underline;
        }
      }

      &__section--avatar {
        min-width: 36px;
      }

      &__section--side {
        padding-right: 4px;
        color: inherit;
      }
    }

    .expand-button {
      .q-item:not(.nav-list__item) {
        padding: 8px 24px;
      }
    }
  }

  .q-item.nav-list__item {
    a {
      padding: 8px 24px;
      color: inherit;
      text-decoration: none;

      &:hover {
        color: inherit;
        text-decoration: underline;
      }
    }

    &--with-icon {
      padding: 8px 24px;

      a {
        padding: 8px 24px 8px 0;
      }
    }
  }

  &__submenu.q-list {
    padding: 0;

    .q-item.nav-list__item {
      a {
        padding: 8px 24px 8px 40px;
      }
    }
  }
}

.actionable-menu {
  .nav-list__item {
    a {
      color: $text-base;
      text-decoration: none;

      &:focus,
      &:hover {
        color: $primary;
        text-decoration: underline;
      }
    }
  }
}

.q-page-sticky {
  z-index: 10000;
}
