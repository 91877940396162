.q-card {
  &__actions {
    .q-btn {
      padding: 1rem 1.6rem;
    }
  }
}


.btn {
  @include border(1px, transparent);

  &__common {
    padding: 1rem 1.6rem;

    .q-icon {
      font-size: 1.4em;
    }

    &--primary {
      background-color: $primary;
      color: $white;

      &:focus {
        @include border(1px, $primary);
        background-color: $white !important;
        color: $primary !important;;
      }
    }

    &--accent {
      background-color: $accent;
      color: $white;

      &:focus {
        @include border(1px, $accent);
        background-color: $white !important;
        color: $accent !important;;
      }
    }

    &--warning {
      @include border(1px, $negative);
      background-color: $negative;
      color: $white;

      &:focus {
        @include border(1px, $negative);
        background-color: $white !important;
        color: $negative !important;;
      }
    }

    &--flat {
      font-size: inherit;
      font-weight: 400;

      &:focus,
      &:hover {
        @include border(1px, currentColor)
      }
    }
  }

  &__icon {
    &:focus,
    &:hover {
      @include border(1px, currentColor)
    }
  }

}

.menu-button {
  position: relative;

  &--alerted {
    &:after {
      content: '';
      position: absolute;
      display: block;
      right: 3px;
      bottom: 3px;
      width: 8px;
      min-width: 8px;
      height: 8px;
      min-height: 8px;
      border-radius: 50%;
      background-color: $negative;
    }
  }
}

.q-btn {
  &.disabled, &[disabled] {
    opacity: 1 !important;
    color: $neutral-40 !important;
  }
}
