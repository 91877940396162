.dialog {
  &__title {
    font-size: 2rem;
  }
}

.promote-dialog {
  &__inner {
    width: 75vw;
    max-width: 75vw !important;
  }
}

.mobile {
  .dialog {
    &__title,
    .heading-3 {
      font-size: 1.8rem;
    }
  }
}

