.item-header {
  &__info {
    @extend %text-small;

    display: flex;
    align-items: center;
    margin-bottom: 32px;
    flex-wrap: wrap;
  }
}

.item__info-table {
  display: grid;
  grid-template-columns: 260px 1fr;
  align-items: center;
  //margin-bottom: 32px;

  > span, > div {
    padding: 1.2rem 0;
  }
}

.mobile {
  .item-header {
    &__info {
      margin-bottom: 24px;
    }
  }

  .item__info-table {
    margin-bottom: 24px;
  }
}
