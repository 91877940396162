.page-toolbar {
  padding: 8px 24px;
  flex-wrap: wrap;
  min-height: 75px;

  &__actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    > *:not(:last-child) {
      margin-right: 16px;
    }
  }
}