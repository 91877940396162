html {
  // This defines what 1rem is
  font-size: 62.5%;
  height: 100%;
}

// WCAG requirements on line height, letter spacing, word spacing and paragraph spacing
body {
  box-sizing: border-box;
  font-family: $base-font;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.6;
  width: 100%;
  height: 100%;
  color: $text-base;

  &.mobile {
    font-size: 1.4rem;
  }
}

.headline-display {
  font-size: 7.2rem;
  line-height: 1.2;
  font-weight: 700;
  color: $brand-header-color;

  .mobile & {
    font-size: 4.8rem;
  }
}

h1, h2, h3, h5, h6,
.heading-1,
.heading-2,
.heading-3 {
  @extend %headings;
}

h1, .heading-1 {
  color: $dark;
  font-size: 4.2rem;

  .mobile & {
    font-size: 3.6rem;
  }
}

h2, .heading-2 {
  font-size: 3.2rem;

  .mobile & {
    font-size: 2.8rem;
  }
}

h3, .heading-3 {
  font-size: 2rem;

  .mobile & {
    font-size: 1.8rem;
  }
}

h4, .heading-4 {
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.6;
}

p {
  margin: 0 0 2rem;
}

.text-small{
  font-size: 1.4rem;
}
