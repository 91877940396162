.status-value {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;

  span {
    white-space: nowrap;
  }
}

.dot {
  width: 12px;
  min-width: 12px;
  height: 12px;
  min-height: 12px;
  border-radius: 50%;
  background-color: $white;
  display: inline;

  &--pending, &--active, &--updatable {
    @include border(1px, $warning);
    background-color: $warning;
  }

  &--approved, &--completed, &--up_to_date {
    @include border(1px, $positive);
    background-color: $positive;
  }

  &--rejected, &--source_not_found {
    @include border(1px, $negative);
    background-color: $negative;
  }

  &--info, &--ahead {
    @include border(1px, $info);
    background-color: $info;
  }

  &--uncompleted, &--skipped, &--irrelevant {
    @include border(1px, $neutral-40);
  }
}
