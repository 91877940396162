//Colors//Homepage
$homepage_image_url: '/images/background.png' !default;

$tooltip-background: #091C34;

$neutral-90: #383737;
$neutral-80: #4E4D4D;
$neutral-70: #646464;
$neutral-60: #6F6F6F;
$neutral-40: #AEADAD;
$neutral-20: #D3D3D3;
$neutral-10: #E9E9E9;
$neutral-5: #FAFAFA;

$white: #FFFFFF;

//Fonts
$typography-font-family: null !default;
$title-font-family: null !default;
$text-base: $neutral-90;


$title-font: if($title-font-family != null, $title-font-family, 'FiraSans'), 'FiraSans', Arial, sans-serif;
$base-font: if($typography-font-family != null, $typography-font-family, 'FiraSans'), 'FiraSans', Arial, sans-serif;

$brand-header-color: $text-base;

$border-radius: 4px;
$button-border-radius: $border-radius;

//Spacing
$space-medium: 32px;

//Sidebar
$sidebar-width: 350px;
$sidebar-mini-width: 64px;
