// ACCESSIBILITY
%visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

// BOX SHADOWS
%shadow--low {
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.05);
}

%shadow--medium{
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

%shadow--high {
  box-shadow: 0 12px 16px rgba(0, 0, 0, 0.2);
}

%shadow--top{
  box-shadow: 0 -2px 6px rgba(0, 0, 0, 0.1);
}

%shadow--right {
  box-shadow: 4px 2px 6px rgba(0, 0, 0, 0.1);
}

%shadow--left {
  box-shadow: -4px 2px 6px rgba(0, 0, 0, 0.1);
}

// TYPOGRAPHY
%headings {
  font-family: $title-font;
  line-height: 1.2;
  font-weight: 500;
}

%text-small {
  font-size: 1.4rem;
}

%dark-semi-bold {
  color: $dark;
  font-weight: 500;
}
