.breadcrumbs {
  display: flex;
  align-items: center;

  &__list {
    display: flex;
    align-items: center;
    list-style: none;
    margin: 0;
  }

  &__item {
    @include border(1px, $neutral-20);

    margin: 8px;
    border-radius: 100px;
    padding: .5em 1em;
    max-width: 200px;

    &:focus {
      @include border(2px, $primary);
    }

    &--link {
      &:hover {
        @include border(1px, $primary);
        color: $primary;
        background-color: $neutral-5;
        text-decoration: underline;
      }
    }
  }

  &__link:link,
  &__link:visited {
    text-decoration: none;
    color: $neutral-90;
    font-size: inherit;
  }
}