.map {
  position: relative;

  &__buttons {
    position: absolute;
    z-index: 999;
    bottom: 20px;
    right: 10px;

    > * {
      margin-left: 10px
    }
  }

  &__sidebar {
    display: none;
  }

  &__container {
    overflow: hidden;
  }

  &--error {
    .geometry__path {
      &--drawable,
      &--drawing {
        stroke: var(--q-negative) !important;
        fill: var(--q-negative) !important;
        stroke-dasharray: 5;
      }
    }
  }

  .geometry__path {
    stroke-width: 4;
    opacity: 1;
    stroke: var(--q-primary);
    fill: var(--q-primary);

    &--highlight {
      stroke: var(--q-accent) !important;
      fill: var(--q-accent) !important;
    }

    &--drawable {
      stroke: var(--q-accent) !important;
      fill: var(--q-accent) !important;
    }

    &--drawing {
      stroke: var(--q-primary) !important;
      fill: var(--q-primary) !important;
    }
  }

  &.map--fullscreen {
    position: fixed !important;
    height: 100%;
    padding: 40px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border: 1px solid;
    z-index: 4000;
    border-radius: 4px;
    background-color: white;

    .map__sidebar {
      display: block;
      position: absolute;
      z-index: 1;
      top: 40px;
      bottom: 40px;
      left: 40px;
      padding: 8px 16px;
      width: 290px;
      background-color: $neutral-5;
      overflow-y: auto;

      & + .map__container {
        width: calc(100% - 290px) !important;
        left: 290px;
      }
    }

    .map__container {
      z-index: 1;
      height: 100% !important;

      &:first-child {
        width: 100% !important;
      }
    }

    .map__buttons {
      bottom: 60px;
      right: 60px;
    }
  }

  &__backdrop {
    position: fixed !important;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.5);
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
}

.mobile {
  .map {
    &.map--fullscreen {
      padding: 10px;

      .map__buttons {
        bottom: 30px;
        right: 20px;
      }
    }
  }
}
