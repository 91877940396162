$primary: #158484;
$secondary: #4AD3A2;
$accent: #6E327D;

$dark: #222121;

$positive: #37BE70;
$negative: #AF1815;
$info: lighten(#224EC0, 25);
$warning: #FFB70B;

@import "../../../../../resources/assets/sass/variable-overwrites.scss";

//Breakpoints
$breakpoint-xs: 599px !default;
$breakpoint-sm: 959px !default;
$breakpoint-md: 1023px !default;
$breakpoint-lg: 1439px !default;
$breakpoint-xl: 1919px !default;
